import { get } from 'svelte/store';
import { getClientAppId, isUnity } from './unity';
import { getMobileOperatingSystem } from './util';
import { institutionIdStore, isAuthenticated } from '../stores/core-store';
import { realInstitutionStore } from '../stores/institutions-store';
import { Logger } from './logs';
import { fetchInstitutionLeaf } from './api/institutions';
import { errorMessage, breadcrumbPaths } from '../stores/core-store';
import { getQueryParameter } from '../util/util';
import { fetchClientApps } from './api/client-apps';

let _gtmID = '';

function setupGoogleAnalytics(gtmID) {
  window.dataLayer = window.dataLayer || [];

  if (!gtmID) {
    console.warn('Google Analytics is disabled.');
    return;
  }

  Logger.log(`Setting up Google Tag Manager: ${gtmID}`);
  _gtmID = gtmID;
}
/**
 * Sends a page_view event to Google Analytics.
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/custom-dims-mets
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
 * @param {string} path
 */
async function sendGoogleAnalyticsPageView(sessionId, tabValue) {
  const dimensions = await getGoogleAnalyticsDimensions(sessionId, tabValue);
  window.dataLayer.push({
    event: 'custom_page_view',
    page_location: window.location.href,
    ...dimensions,
  });
}

async function getGoogleAnalyticsDimensions(sessionId, tabValue) {
  const dimensions = {};

  // Dimension1 = platform
  if (isUnity()) {
    const os = getMobileOperatingSystem();
    if (os === 'iOS') {
      dimensions.platform = 'iOS';
    } else if (os === 'Android') {
      dimensions.platform = 'Android';
    } else {
      dimensions.platform = 'Unknown';
    }
  } else {
    dimensions.platform = 'Web';
  }

  if (!get(isAuthenticated)) {
    return dimensions;
  }

  // Dimension2 = gigxr_institution_id
  const institutionId = get(institutionIdStore);
  if (institutionId) {
    dimensions['gigxr_institution_id'] = institutionId;
  }

  // Dimension3 = gigxr_institution_name
  let institution = get(realInstitutionStore);
  if (!institution && institutionId) {
    institution = await fetchInstitutionLeaf(institutionId);
  }
  if (institution) {
    dimensions['gigxr_institution_name'] = institution.institutionName;
  }

  // Dimension4 = gigxr_session_id
  if (sessionId) {
    dimensions['gigxr_session_id'] = sessionId;
  }

  // Dimension5 = gigxr_headset_login_method
  if (tabValue) {
    dimensions['gigxr_headset_login_method'] = tabValue;
  }

  if (isUnity()) {
    // Dimension6 = gigxr_client_app_id
    const clientAppID = getClientAppId();
    dimensions['gigxr_client_app_id'] = clientAppID;
    try {
      const clientApps = await fetchClientApps();
      const clientAppHit = clientApps.find((app) => app.clientAppId === clientAppID);
      if (clientAppHit) {
        // Dimension7 = gigxr_app_name
        dimensions['gigxr_app_name'] = clientAppHit.clientAppName;
      }
    } catch {}
  } else {
    // Dimension6 = gigxr_client_app_id
    dimensions['gigxr_client_app_id'] = '00000';
    // Dimension7 = gigxr_app_name
    dimensions['gigxr_app_name'] = 'GMS Web';
  }

  return dimensions;
}

const sessionIdRoutes = ['/sessions/view', '/sessions/lobby', '/sessions/join'];
const sessionPlanIdRoutes = ['/sessions/plans/view'];

async function sendPageViewEvent(path) {
  errorMessage.set('');
  breadcrumbPaths.set([]);

  let tabValue = getQueryParameter('tab');
  tabValue = tabValue === 'qr' || tabValue === 'code' ? tabValue : undefined;

  let sessionAndPlanId = undefined;
  const sessionAndPlanIdRouteHit = [...sessionIdRoutes, ...sessionPlanIdRoutes].find((sessionIdAndPlanRoute) =>
    path.startsWith(`${sessionIdAndPlanRoute}`),
  );
  if (sessionAndPlanIdRouteHit) {
    sessionAndPlanId = path.replace(`${sessionAndPlanIdRouteHit}/`, '').split('?')[0];
  }

  await sendGoogleAnalyticsPageView(sessionAndPlanId, tabValue);
}

export { setupGoogleAnalytics, sendGoogleAnalyticsPageView, sendPageViewEvent };
